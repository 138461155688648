import React from 'react';
import PropTypes from 'prop-types';

import {
  CardsContainer, StepContainer, Row, Label, SmallContent, Question, AnswerCard, Title, Content,
} from './styledComponents';
import ProgressBarVertical from './ProgressBarVertical';
import Dots from '../Home/v3/Dots';

const Step1Problem = ({ treeProblems: { problems }, selectProblem, selectedProblems, selectedCloth }) => {
  const problemsWithoutTalkRDV = problems.filter(({ slug }) => slug !== 'talkRDV');
  return (
    <Row marginTop={83} marginTopMobile={50}>
      <Content>
        <Title>Que peut-on faire pour votre client ?</Title>
        <Dots justifyContent="center" noMargin />
        <Row marginTop={66}>
          <StepContainer>
            <ProgressBarVertical
              isActive={Object.keys(selectedProblems).length > 0}
              height={problemsWithoutTalkRDV.length < 4 ? 141 : 199}
              noLine={
                selectedCloth !== 'acce'
                && (Object.keys(selectedProblems).length === 0
                || (Object.keys(selectedProblems).length === 1 && Object.keys(selectedProblems)[0] === 'talkRDV'))
              }
            />
            <SmallContent>
              <Question>Indiquez-nous le problème :</Question>
              <Row>
                <CardsContainer smallMarginTop mobileCenter>
                  {problemsWithoutTalkRDV.map(({ slug, label }) =>
                    <AnswerCard
                      key={slug}
                      slug={slug}
                      onClick={() => selectProblem(slug)}
                      isActive={!!selectedProblems[slug]}
                    >
                      <Label>
                        {label}
                      </Label>
                    </AnswerCard>)
                  }
                </CardsContainer>
              </Row>
            </SmallContent>
          </StepContainer>
        </Row>
      </Content>
    </Row>
  );
};

Step1Problem.propTypes = {
  selectProblem: PropTypes.func.isRequired,
  selectedProblems: PropTypes.shape({
    problems: PropTypes.array,
  }).isRequired,
  treeProblems: PropTypes.shape({
    problems: PropTypes.array,
  }).isRequired,
  selectedCloth: PropTypes.string.isRequired,
};

export default Step1Problem;
