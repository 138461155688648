import React from 'react';
import PropTypes from 'prop-types';
import { Row, Title, Content, CardsContainer } from './styledComponents';
import Dots from '../Home/v3/Dots';
import CardSelector from './CardSelector';

const Step1Pieces = ({ pieces, selectPiece, selectedPiece }) => (
  <Row>
    <Content>
      <Title>De quelle pièce s’agit-il ?</Title>
      <Dots justifyContent="center" noMargin />
      <Row>
        <CardsContainer>
          {pieces.map(piece =>
            <CardSelector
              key={piece.slug}
              slug={piece.slug}
              image={selectedPiece === piece.slug ? piece.images.white : piece.images.navy}
              alt={`Tilli retouche votre ${piece.name}`}
              label={piece.name}
              onClick={selectPiece}
              isActive={selectedPiece === piece.slug}
              hasLogoImage
            />,
          )}
        </CardsContainer>
      </Row>
    </Content>
  </Row>
);

Step1Pieces.propTypes = {
  pieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectPiece: PropTypes.func.isRequired,
  selectedPiece: PropTypes.string.isRequired,
};

export default Step1Pieces;
