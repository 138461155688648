import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getTreeLocations, getTreeProblems } from '../../services/treeSelector';
import { Wrapper, Block, PageTitle, mobileThresholdPixels } from './styledComponents';
import { StepContainer as StepContainerV3, ComponentsContainer, colors as colorsV3 }
  from '../../components/Home/v3/styledComponents';
import { Button } from '../../components/Home/v2/StyledComponents';
import ArrowButton from '../../components/order/LogoButton';
import ProgressBar from '../../components/order/ProgressBar';

import Step1Pieces from './Step1Pieces';
import Step1Fabrics from './Step1Fabrics';
import Step1Problems from './Step1Problems';
import Step1Locations from './Step1Locations';
import Step1Multiple from './Step1Multiple';
import Step1Lining from './Step1Lining';

import clothes from './clothes';
import tree from './tree';

const Relative = styled.div`
  position: relative;
`;

const ScrollingPosition = styled.div`
  position: absolute;
  top: -43px;
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ mobileLow }) => mobileLow && 'top: -103px;'}
  }
`;

const StepContainer2 = styled(StepContainerV3)`
  padding: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    margin: 45px auto 0px;
  }
`;


const StyledButton = styled(Button)`
  text-transform: none;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  ${props => !props.isAvailable && `
    border-color: ${colorsV3.lightGrey3};
    color: ${colorsV3.lightGrey3};
    pointer-events: none;
    cursor: unset;`}

  @media (max-width: ${mobileThresholdPixels}) {
    height: 45px;
    font-size: 13px;
    min-width: 210px;
    padding: 10px;
    margin: 10px 37px 0px;
    ${props => props.mobileOrder && 'margin-top: 0px;'}
  }
`;

const StyledLink = styled.div`
  text-decoration: none;
  @media (max-width: ${mobileThresholdPixels}) {
    order: -1;
  }
`;

const createRange = (min, max) => {
  const range = [];
  for (let i = min; i < max + 1; i += 1) {
    range.push({ value: i, label: i.toString() });
  }
  return range;
};

const Step1 = ({
  cloth, fabric, problems, lining, isLiningDisplayed,
  selectPiece, selectFabric, selectProblem, selectLocation, selectLining, setSelectValue, setRef,
  renderSummary, isSelectingDone, goNext, addPiece,
}) => (
  <Wrapper>
    <Block>
      <ProgressBar step={1} />

      <PageTitle>
        Dites-nous ce dont votre client a besoin en 2 min chrono
      </PageTitle>

      <Step1Pieces
        pieces={clothes}
        selectPiece={selectPiece}
        selectedPiece={cloth}
      />

      <Relative>
        <ScrollingPosition>
          <div ref={component => setRef('Step1Fabrics', component)} />
        </ScrollingPosition>
      </Relative>

      {!!cloth &&
        <Step1Fabrics
          selectFabric={selectFabric}
          selectedFabric={fabric}
          selectedCloth={cloth}
        />
      }

      <Relative>
        <ScrollingPosition mobileLow>
          <div ref={component => setRef('Step1Problems', component)} />
        </ScrollingPosition>
      </Relative>

      {!!fabric &&
        <Step1Problems
          selectedProblems={problems}
          selectProblem={selectProblem}
          treeProblems={getTreeProblems(tree, cloth)}
          selectedCloth={cloth}
        />
      }

      {!!problems &&
        Object.keys(problems).map(problem =>
          problem !== 'talkRDV' && !problem.includes('_multiple') ?
            <Step1Locations
              key={problem}
              selectedLocations={problems[problem]}
              selectLocation={slug => selectLocation(problem, slug)}
              treeLocations={getTreeLocations(tree, cloth, problem)}
            />
            :
            <div key={problem} />,
        )
      }

      {!!problems &&
        Object.keys(problems).map((problem, index) =>
          problem.includes('_multiple') && problems[problem].length > 0 ?
            <Step1Multiple
              key={problem}
              slug={problem}
              createRange={createRange}
              setSelectValue={setSelectValue}
              selectedValue={problems[problem][0]}
              isLast={cloth === 'acce' && index === Object.keys(problems).length - 1}
            />
            : <div key={problem} />,
        )
      }

      {isLiningDisplayed &&
        <Step1Lining
          selectLining={selectLining}
          selectedLining={lining}
        />
      }

      {renderSummary()}

      {isSelectingDone && (
        <StepContainer2>
          <ComponentsContainer>
            <StyledButton onClick={addPiece} transparent isAvailable>
              + Ajouter une pièce
            </StyledButton>
            <StyledLink onClick={goNext}>
              <ArrowButton>OK, on passe au RDV !</ArrowButton>
            </StyledLink>
          </ComponentsContainer>
        </StepContainer2>
      )}
    </Block>
  </Wrapper>
);

Step1.propTypes = {
  cloth: PropTypes.string.isRequired,
  fabric: PropTypes.string.isRequired,
  problems: PropTypes.shape({}).isRequired,
  lining: PropTypes.string.isRequired,
  isLiningDisplayed: PropTypes.bool.isRequired,
  selectPiece: PropTypes.func.isRequired,
  selectFabric: PropTypes.func.isRequired,
  selectProblem: PropTypes.func.isRequired,
  selectLocation: PropTypes.func.isRequired,
  selectLining: PropTypes.func.isRequired,
  setSelectValue: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  renderSummary: PropTypes.func.isRequired,
  isSelectingDone: PropTypes.bool,
  goNext: PropTypes.func.isRequired,
  addPiece: PropTypes.func.isRequired,
};

Step1.defaultProps = {
  isSelectingDone: false,
};

export default Step1;
